<template>
	<div>
		<!--顶部工具条-->
		<el-col :span="24">
			<el-form :inline="true">
				<el-form-item label="审核类型:">
					<el-select v-model="filters.contentUpdateStatus" clearable>
						<el-option v-for="item in contentUpdateData" :key="item.key" :label="item.value"
							:value="item.key">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="审核状态:">
					<el-select v-model="filters.approval" clearable>
						<el-option v-for="item in approvalData" :key="item.key" :label="item.value"
							:value="item.key">
						</el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="书籍分类:">
					<el-select v-model="filters.bizTypeId" clearable>
						<el-option v-for="item in bizTypeData" :key="item.key" :label="item.value" :value="item.key">
						</el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item>
					<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
				</el-form-item>
			</el-form>
		</el-col>
		<!--列表-->
		<el-table :data="users" highlight-current-row @current-change="selectCurrentRow" v-loading="listLoading"
			@selection-change="selsChange" style="width: 100%" border>
			<!-- <el-table-column type="selection" width="50">
			</el-table-column> -->
			<el-table-column prop="contentUpdateStatusName" label="审核类型" align="center" show-overflow-tooltip
				width="100">
				<template slot-scope="scope">
					<el-tag size="medium"
						:type="scope.row.contentUpdateStatus == 1 ? 'warning' : scope.row.contentUpdateStatus == 2 ? 'success' : scope.row.contentUpdateStatus == 3 ? 'danger' : 'info'"
						disable-transitions>{{ scope.row.contentUpdateStatusName }}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="bookName" label="书籍名称" align="center">
			</el-table-column>
			<el-table-column prop="bizTypeName" label="书籍分类" align="center" show-overflow-tooltip
				width="150"></el-table-column>
			<el-table-column prop="applyAuditUserName" label="提交人" width="100" align="center"></el-table-column>
			<el-table-column prop="applyAuditTime" label="提交时间" width="170" align="center"></el-table-column>
			<el-table-column prop="roleNames" label="审核状态" align="center" width="100">
				<template slot-scope="scope">
					<el-tag size="medium" effect="dark"
						:type="scope.row.approval == 1 ? 'info' : scope.row.approval == 2 ? 'warning' : scope.row.approval == 3 ? 'success' : 'danger'"
						disable-transitions>{{ scope.row.approvalName }}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="confirmAuditUserName" label="审核人" width="100" align="center"></el-table-column>
			<el-table-column prop="confirmAuditTime" label="审核时间" width="170" align="center"></el-table-column>
			<el-table-column align="center" label="操作" width="70">
				<template slot-scope="scope">
					<div v-if="scope.row.approval == 2">
						<el-button v-if="scope.row.contentUpdateStatus == 3" type="text" size="small"
							@click.stop="handleDelAudit(scope.row)">审核</el-button>
						<el-button v-else type="text" size="small" @click.stop="checkBookContent(scope.row)">审核</el-button>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<!--底部工具条-->
		<el-col :span="24" class="pageBar">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="pages.pageIndex" :page-sizes="pages.pageArr" :page-size="pages.pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="pages.dataCount">
			</el-pagination>
		</el-col>
		<BookMenu v-if="showBookMenu" :visible.sync="showBookMenu" :bookId="itemValue.bookId"
			:approval="itemValue.approval" :title="itemValue.bookName" :refreshList="refreshList" :menuType="'audit'">
		</BookMenu>
	</div>
</template>

<script>
import util from "../../../util/date";
import { validPhone } from "../../../util/validate";
import {
	getBookDataPageList,
	getByNamespace,
	addBook,
	commitBookForAudit,
	editBook,
	deleteBook,
	bookAudit
} from "../../api/api";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import BookMenu from "../../components/BookMenu";
export default {
	components: { Toolbar, BookMenu },
	data() {
		return {
			utils: '',
			users: [],
			roles: [],
			listLoading: false,
			currentRow: null,
			//列表选中列
			sels: [],
			//增删改查按钮
			buttonList: [],
			//顶部筛选条件
			filters: {
				contentUpdateStatus: null,
				bizTypeId: null
			},
			//关于分页的obj
			pages: {
				pageSize: 20, //默认每页条数
				pageArr: [10, 20, 30, 40], //默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			approvalData: [
				{
					value: 1,
					name: '未提交'
				},
				{
					value: 2,
					name: '审核中'
				},
				{
					value: 3,
					name: '审核通过'
				},
				{
					value: 4,
					name: '审核拒绝'
				},
			],
			bizTypeData: [

			],
			approvalData: [

			],
			contentUpdateData: [

			],
			showBookMenu: false,
			itemValue: {},
		};
	},
	methods: {
		// 清空筛选框内容，重新获取数据
		clearContent() {
			this._getListData()
		},
		//当前行发生变化时
		selectCurrentRow(val) {
			this.currentRow = val;
		},
		//选项发生变化时
		selsChange: function (sels) {
			this.sels = sels;
		},
		checkBookContent: function (row) {
			this.itemValue = row
			this.showBookMenu = true

		},
		// 查询
		getDataList() {
			this.pages.pageIndex = 1
			this._getListData()
		},
		refreshList() {
			this._getListData()
		},
		//获取书籍列表
		_getListData() {
			this.listLoading = true;
			var params = {
				pageIndex: this.pages.pageIndex,
				pageSize: this.pages.pageSize,
				bookName: this.filters.bookName,
				approval: this.filters.approval,
				bizTypeId: [4],
				contentUpdateStatus: this.filters.contentUpdateStatus,
				forAudit: true
			};
			getBookDataPageList(params).then((res) => {
				this.users = res.data.response.data;
				this.listLoading = false;

				this.pages.dataCount = res.data.response.dataCount;
				this.pages.pageIndex = res.data.response.pageIndex;
				if (res.data.response.pageSize > 0) {
					this.pages.pageSize = res.data.response.pageSize;
				}
			});
		},
		callFunction(item) {
			this[item.func].apply(this, item);
		},
		handleDelAudit(row) {
			this.$confirm(`是否同意删除${row.bookName}`, '删除审核', {
				confirmButtonText: '通过',
				cancelButtonText: '不通过',
			})
				.then(_ => {
					let params = {
						bookId: row.bookId,
						status: 3
					}
					bookAudit(params).then(res => {
						if (res.data.success) {
							this.$message({
								message: '操作成功',
								type: "success",
							});
							this.$emit('update:visible', this.dialogVisible);
							this._getListData()
						} else {
							this.$message({
								message: `操作失败, ${res.data.message}`,
								type: "error",
							});
						}
					})
				})
				.catch(_ => {
					let params = {
						bookId: row.bookId,
						status: 4
					}
					bookAudit(params).then(res => {
						if (res.data.success) {
							this.$message({
								message: '操作成功',
								type: "success",
							});
							this.$emit('update:visible', this.dialogVisible);
							this._getListData()
						} else {
							this.$message({
								message: `操作失败, ${res.data.message}`,
								type: "error",
							});
						}
					})
				});
		},
		// 分页
		handleCurrentChange(val) {
			// if(this.filters.name != '' || this.filters.selectc != false){
			//   this.pages.pageIndex = 1
			// }else{
			this.pages.pageIndex = val;
			// }      
			this._getListData();
		},
		handleSizeChange(val) {
			this.pages.pageSize = val;
			this._getListData();
		},
		handleConfirm() {

		},
		getBizTypeList() {
			getByNamespace({ namespace: 'BizService.OmsAicsService.BizType' }).then(res => {
				this.bizTypeData = res.data.response
			})
		},
		getApprovalList() {
			getByNamespace({ namespace: 'BizService.OmsAicsService.ApprovalStatus' }).then(res => {
				this.approvalData = res.data.response
			})
		},
		getContentUpdateList() {
			getByNamespace({ namespace: 'BizService.OmsAicsService.ContentUpdateStatus' }).then(res => {
				this.contentUpdateData = res.data.response
			})
		},
	},
	mounted() {
		this.utils = util
		this._getListData();
		this.getBizTypeList()
		this.getApprovalList()
		this.getContentUpdateList()
		let routers = window.localStorage.router
			? JSON.parse(window.localStorage.router)
			: [];
		this.buttonList = getButtonList(this.$route.path, routers);
	},
};
</script>
<style lang="stylus" scoped>

.el-upload-dragger .el-icon-upload {
	margin-top: 10px;
}



.rolesTop {
	width: 100%;
	display: flex;
}

.rolesInp {
	width: 300px;
	margin-right: 20px;
}

.pageBar {
	display: flex;
	justify-content: center;
  	margin-top: 15px;
}

</style>
